import React, { useState } from 'react'
import './services.css'

function Our_services() {

    const [show, setShow] = useState(false)

  return (
    <>
        {/* <!-- Start service --> */}
		<section className="services section">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title">
							<h2>Our Services</h2>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-md-6 col-12">
						{/* <!-- Start Single Service --> */}
						<div className="single-service">
                            <i className="fa fa-building"></i>
							<h4>Property & land law</h4>
							<p>All of your due diligence, sale and purchase agreements, transfers under one roof as well as legal cases such a land encroachment & trespassing. We will make sure your sale or purchase doesn’t turn into a nightmare.</p>	
						</div>
						{/* <!-- End Single Service --> */}
					</div>
					<div className="col-lg-4 col-md-6 col-12">
						{/* <!-- Start Single Service --> */}
						<div className="single-service">
                            <i className="fa fa-gavel"></i>
							<h4>Wills & Probate</h4>
							<p>We prepare detailed Wills and assist with the legal aspects regarding assets and estate distribution, child custody in the sad event of death of parent & more. Contact the team today to discuss making a will or updating an existing one.</p>	
						</div>
						{/* <!-- End Single Service --> */}
					</div>
					<div className="col-lg-4 col-md-6 col-12">
						{/* <!-- Start Single Service --> */}
						<div className="single-service">
                            <i className="fa fa-file"></i>
							<h4>Translation & Notary Services</h4>
							<p>here will come a time when we all need something translated whether it’s a contract, staff employment rules, passports or other, our office provides professional translations from Thai – English and from English to Thai for all types of documents.</p>	
						</div>
						{/* <!-- End Single Service --> */}
					</div>
					<div className="col-lg-4 col-md-6 col-12">
						{/* <!-- Start Single Service --> */}
						<div className="single-service">
                            <i className="fa fa-briefcase"></i>  
							<h4>Business and services licenses Company registration</h4>
							<p>We offer professional advice on opening a company in Thailand and hold your hand every step of the way, explaining everything along the way while we complete your company registration for either Co,.Ltd, sole trader,Limited partnership or BOI company types.</p>	
						</div>
						{/* <!-- End Single Service --> */}
					</div>
					<div className="col-lg-4 col-md-6 col-12">
						{/* <!-- Start Single Service --> */}
						<div className="single-service">
                            <i className="fa-solid fa-money-bill-transfer"></i>
							<h4>Company sales and transfers</h4>
							<p>Are you selling your company or perhaps you are buying a business? 
                                Our team take the stress away and make sure the sale and transfer of businesses goes smoothly and legally.</p>	
						</div>
						{/* <!-- End Single Service --> */}
					</div>
					<div className="col-lg-4 col-md-6 col-12">
						{/* <!-- Start Single Service --> */}
						<div className="single-service">
                            <i className="fa fa-rotate-left"></i>
							<h4>Company Structure Changes</h4>
							<p>Our office provide full company structure changes for DBD registered businesses including director additions, director removals, shareholder additions, shareholder removals & share percentages restructuring.</p>	
						</div>
						{/* <!-- End Single Service --> */}
					</div>
				</div>
                <button className="btn text-center" onClick={()=> setShow(!show)}>Other services</button>

                    {
                    show?<div className="row mt-3">
                        <div className="col-lg-4 col-md-6 col-12">
                            {/* <!-- Start Single Service --> */}
                            <div className="single-service">
                                <i className="fa fa-home"></i>
                                <h4>Guesthouse & hotel license</h4>
                                <p>A hotel licence involves a complicated and difficult process to obtain and therefore should be handled by an experienced attorney in order to get a successful application. Contact our team today to start your guesthouse or hotel licence.</p>	
                            </div>
                            {/* <!-- End Single Service --> */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            {/* <!-- Start Single Service --> */}
                            <div className="single-service">
                                <i className="fa-solid fa-utensils"></i>
                                <h4>Restaurant license</h4>
                                <p>Registered restaurant businesses in Thailand are required to hold a valid food licence, it isn’t a particularly complex licence to obtain but there are a few important requirements of your restaurant in order to successfully obtain one. If you require assistance or would like us to handle the process for you then contact our team who will be glad to assist you.</p>	
                            </div>
                            {/* <!-- End Single Service --> */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            {/* <!-- Start Single Service --> */}
                            <div className="single-service">
                                <i className="fa-solid fa-wine-bottle"></i>
                                <h4>Alcohol & Tobacco License</h4>
                                <p>If your business sells alcohol or tobacco then you are required by Thai law to hold a valid licence accordingly. There is also a different imported alcohol licence required for business who sell certain imported alcohol.</p>	
                            </div>
                            {/* <!-- End Single Service --> */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            {/* <!-- Start Single Service --> */}
                            <div className="single-service">
                                <i className="fa-solid fa-screwdriver-wrench"></i>
                                <h4>construction permit</h4>
                                <p>A construction permit is required by law to build, demolish or change the structure of a previously approved building in Thailand. Not obtaining a construction permit can land you in serious hot water and in the worst cases lead to your new building being demolished.</p>	
                            </div>
                            {/* <!-- End Single Service --> */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            {/* <!-- Start Single Service --> */}
                            <div className="single-service">
                                <i className="fa-solid fa-plane-departure"></i>
                                <h4>Operating A Tourism Based business</h4>
                                <p>There are different types and levels of TAT licence in Thailand relating to the field in which your business falls into.
                                    Businesses such a tour shops, scuba diving, mini van, boat tours are all required to hold a type of TAT licence by law.</p>	
                            </div>
                            {/* <!-- End Single Service --> */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            {/* <!-- Start Single Service --> */}
                            <div className="single-service">
                                <i className="fa-solid fa-id-card"></i>
                                <h4>TAT license</h4>
                                <p>There are different levels of TAT ( travel and tourism licence ) which all offer a different level of coverage and serve different purposes. Examples of requiring TAT licences are tour shops selling tour tickets, tour mini van companies, boat charter companies & diving companies to name just a few.</p>	
                            </div>
                            {/* <!-- End Single Service --> */}
                        </div>
                        <div className="col-lg-4 col-md-6 col-12">
                            {/* <!-- Start Single Service --> */}
                            <div className="single-service">
                                <i className="fa-solid fa-passport"></i>
                                <h4>Thailand Elite Visa</h4>
                                <p>SPR Accounting is an authorized agent of the Thailand Elite visa program, our team prepares and applies for your long term Elite stay visa at no extra cost, our fees are exactly the same as the Thailand Elite Visa department, so why not sit back, relax and let us do all the paperwork for you at no extra cost.</p>	
                            </div>
                            {/* <!-- End Single Service --> */}
                        </div>
                    </div>:null 
                    }
			</div>
		</section>
		{/* <!--/ End service --> */}

        {/* <!-- Start clients --> */}
		<div className="clients overlay">
			<div className="container">
                <div className=" col-lg-12 col-md-12 col-12 ">
                    <div class="content text-center ">				
                        <h2>Professional legal services for all your real estate needs</h2>
                    </div>
                </div>
			</div>
		</div>
		{/* <!--/Ens clients --> */}
    </>
  )
}

export default Our_services