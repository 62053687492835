import React from 'react'
import './Schedule.css'
import { Link } from 'react-router-dom'

function Schedule() {
  return (
    <>
        <section className="schedule">
			<div className="container">
				<div className="schedule-inner">
					<div className="row">
						<div className="col-lg-4 col-md-6 col-12 ">
							{/* <!-- single-schedule --> */}
							<div className="single-schedule first">
								<div className="inner">
									<div className="icon">
                                        <i className="fa fa-user"></i>
									</div>
									<div className="single-content">
										<h4>Professional Accountant</h4>
										<p>Service with a quality team and ready to take care of you in every aspect.</p>
										<Link to="/accounting">LEARN MORE<i className="fa fa-long-arrow-right"></i></Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-12">
							{/* <!-- single-schedule --> */}
							<div className="single-schedule middle">
								<div className="inner">
									<div className="icon">
                                        <i className="fa fa-gavel"></i>
									</div>
									<div className="single-content">
										<h4>Legal Expert</h4>
										<p>No matter what the law is about, We are ready to proceed to the best of our ability.</p>
										<Link to="/legal">LEARN MORE<i className="fa fa-long-arrow-right"></i></Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-12 col-12">
							{/* <!-- single-schedule --> */}
							<div className="single-schedule last">
								<div className="inner">
									<div className="icon">
										<i className="icofont-ui-clock"></i>
									</div>
									<div className="single-content">
										<h4>Opening Hours</h4>
										<ul className="time-sidual">
											<li className="day">Monday - Friday <span>9.00 am. - 5.00 pm.</span></li>
											<li className="day">Office close <span>Saturday and Sunday</span></li>
										</ul>
										<Link to="/contact">CONTACT NOW<i className="fa fa-long-arrow-right"></i></Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

        <section className="Feautes section">
			<div className="container">
				<div className="row">
					<div className="col-lg-12"> 
						<div className="section-title">
							<h2>ONE OF KOH SAMUI’S LEADING ACCOUNTING & LEGAL FIRMS</h2>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-4 col-12">
						{/* <!-- Start Single features --> */}
						<div className="single-features">
							<div className="signle-icon">
                                <i className="fa fa-calculator"></i>
							</div>
							<h3>End of year financial Audits</h3>
							<p>All registered businesses must complete an end of financial year audit, our team handle every step of this process to keep your business stay straight.</p>
						</div>
						{/* <!-- End Single features --> */}
					</div>
					<div className="col-lg-4 col-12">
						{/* <!-- Start Single features --> */}
						<div className="single-features">
							<div className="signle-icon">
                                <i className="fa fa-file"></i>
							</div>
							<h3>Tax Refunds</h3>
							<p>Our team handle all aspects of taxation including VAT, Withholding tax, Corporate tax and land taxes.</p>
						</div>
						{/* <!-- End Single features --> */}
					</div>
					<div className="col-lg-4 col-12">
						{/* <!-- Start Single features --> */}
						<div className="single-features last">
							<div className="signle-icon">
                                <i className="fa fa-book"></i>
							</div>
							<h3>Monthly book Keeping</h3>
							<p>We take care of all your booking keeping on a monthly basis to leave you in good standing for your end of year financial audit.</p>
						</div>
						{/* <!-- End Single features --> */}
					</div>
				</div>
			</div>
		</section>
    </>
  )
}

export default Schedule