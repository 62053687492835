import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
        {/* <!-- Footer Area --> */}
		<footer id="footer" className="footer ">
			{/* <!-- Footer Top --> */}
			<div className="footer-top">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6 col-12">
							<div className="single-footer">
								<h2>Social</h2>
								{/* <!-- Social --> */}
								<ul className="social">
									<li><a 
                                        href="https://www.facebook.com/sprlegal?refid=52&paipv=0&eav=AfaGYG7cJQhdI0AvlinbZGIJoByY_tP3HQ96Dly9EiSzrMTimgvOz7hHxjmGgE3Z_os" target="_blank" rel="noreferrer">
                                            <i className="icofont-facebook"></i>
                                        </a>
                                    </li>
									<li><a href="https://line.me/ti/p/AklxK693PF" target="_blank" rel="noreferrer"><i className="icofont-line"></i></a></li>
									<li><a href="http:/api.whatsapp.com/send?phone=+928192363" target="_blank" rel="noreferrer"><i className="icofont-whatsapp"></i></a></li>
								</ul>
								{/* <!-- End Social --> */}
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-12">
							<div className="single-footer f-link">
								<h2>Quick Links</h2>
								<div className="row">
									<div className="col-lg-6 col-md-6 col-12">
										<ul>
											<li><Link to="/"><i className="fa fa-caret-right" aria-hidden="true"></i>Home</Link></li>
											<li><a href="#fun-facts"><i className="fa fa-caret-right" aria-hidden="true"></i>About Us</a></li>
											<li><Link to="/accounting"><i className="fa fa-caret-right" aria-hidden="true"></i>Accounting</Link></li>
										</ul>
									</div>
                                    <div className="col-lg-6 col-md-6 col-12">
										<ul>
											<li><a href="/legal"><i className="fa fa-caret-right" aria-hidden="true"></i>legal</a></li>
											<li><a href="/visa-and-workpermit"><i className="fa fa-caret-right" aria-hidden="true"></i>Visa and work permit</a></li>	
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-6 col-12">
							<div className="single-footer">
								<h2>Open Hours</h2>
								<ul className="time-sidual">
									<li className="day">Monday - Friday <span>9.00 am.- 5.00 pm.</span></li>
									<li className="day">Office close <span>Saturday and Sunday</span></li>
									<li className="day">Head Office <a href="tel:077425201"><span>077-425-201</span></a></li>
									<li className="day">Koh Samui<a href="tel:0928192363"><span>092-819-2363</span></a></li>
									<li className="day">Phuket <a href="tel:0623832299"><span>062-383-2299</span></a></li>
								</ul>
							</div>
						</div>
		
					</div>
				</div>
			</div>
			{/* <!--/ End Footer Top --> */}
			{/* <!-- Copyright --> */}
			<div className="copyright">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-12">
							<div className="copyright-content">
								<p>© Copyright 2023  |  All Rights Reserved </p>
                            </div>
						</div>
					</div>
				</div>
			</div>
			{/* <!--/ End Copyright --> */}
		</footer>
		{/* <!--/ End Footer Area --> */}
    </>
  )
}

export default Footer