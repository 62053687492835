import React, { useEffect, useState } from "react";
import "./loading.css";

function Loading() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 800);
  }, []);


  return (
    <> {loading? 
        <div className="preloader">
            <div className="loader">
                <div className="loader-outter"></div>
                <div className="loader-inner"></div>
            </div>
        </div>:null}
    </>
  );
}

export default Loading;
