import React from "react";
import { motion } from "framer-motion";
import { fadeIn, staggerChildren } from "../utils/motion";
import "./accounting.css";

function AccountingPage() {
  return (
    <div className="accounting-body">
      {/* <!-- Breadcrumbs --> */}
      <div className="breadcrumbs overlay">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Accounting Service</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Breadcrumbs --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.5, 1)}
                className="section-title"
              >
                <h2>Financial year</h2>
              </motion.div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("right", "tween", 1, 1)}
                className="choose-left"
              >
                <p>
                  The financial year in thailand runs from January 1st -
                  December 31st
                </p>
                <p>
                  All registered businesses in Thailand are required to pay
                  their applicable taxes, failure to comply or have accurate
                  accounting can result in fines and investigations into your
                  business financial affairs with serious consequences.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("up", "tween", 1.5, 1)}
                  className="video-image"
                >
                  <img
                    src="./assets/images/Financial.png"
                    alt="SPR-Accounting"
                  />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.5, 1)}
                className="section-title"
              >
                <h2>Tax Returns & Tax Related</h2>
              </motion.div>
            </div>
          </div>
          <div className="row reverse">
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("right", "tween", 1, 1)}
                  className="video-image"
                >
                  <img
                    src="./assets/images/taxReturn.png"
                    alt="SPR-Accounting"
                  />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>

            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("up", "tween", 1.5, 1)}
                className="choose-left"
              >
                <p>
                  Our team handle all aspects of taxation including VAT,
                  Withholding tax, Corporate tax and land taxes.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div variants={fadeIn("down", "tween", 0.5, 1)} className="section-title">
                <h2>Monthly book keeping</h2>
              </motion.div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div variants={fadeIn("right", "tween", 1, 1)} className="choose-left">
                <p>
                  With good bookkeeping you will easily be able to keep track
                  and monitor your sales, see the areas which require
                  improvement and which areas are doing the best, you will also
                  be able to see your income vs expenses all in one place to
                  give you an accurate monthly overview or profit and loss.
                  monthly bookkeeping is also an important tool to prepare your
                  end of year financial statement for audit and tax filings.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>

            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div variants={fadeIn("up", "tween", 1.5, 1)} className="video-image">
                  <img
                    src="./assets/images/Monthly-book.png"
                    alt="SPR-Accounting"
                  />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}
    </div>
  );
}

export default AccountingPage;
