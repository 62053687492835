import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from './pages/Homepage';
import Accounting from './pages/Accounting';
import LegalPage from './pages/LegalPage';
import VisaPage from './pages/VisaPage';
import ScrollToTop from './pages/ScrollToTop';
import Contactpage from './pages/Contactpage';

function App() {
  return (
    <Router>
        <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Homepage/>}/>
        <Route path="/accounting" element={<Accounting/>}/>
        <Route path="/legal" element={<LegalPage/>}/>
        <Route path="/visa-and-workpermit" element={<VisaPage/>}/>
        <Route path="/contact" element={<Contactpage/>}/>
      </Routes>
    </Router>
  );
}

export default App;