import React from "react";
import Navbar from "../components/navbar/Navbar";
import Footer from "../components/footer/Footer";
import Loading from "../components/loading/Loading";
import Visa from "../components/visa/Visa";

function VisaPage() {
  return (
    <>
        <Loading/>
        <Navbar/>
        <Visa/>
        <Footer/>
    </>
  );
}

export default VisaPage;
