import React from "react";
import "./visa.css";
import { motion } from "framer-motion";
import { fadeIn, staggerChildren } from "../utils/motion";

function Visa() {
  return (
    <div className="visa-body">
      {/* <!-- Breadcrumbs --> */}
      <div className="breadcrumbs visa overlay">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>visa and work permit services</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Breadcrumbs --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.2, 1.5)}
                className="section-title visa-section"
              >
                <h2>
                  visa & work permits <br></br> visas for foreigners to stay in
                  thailand
                </h2>
              </motion.div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("right", "tween", 0.5, 1.5)}
                className="choose-left"
              >
                <p>
                  Our expert team can take the headache away from all of the
                  constantly changing requirements of the Thai Immigration
                  bureau. We service all types of long stay visas including
                  NON'B work visas, NON'O marriage visas, NON'O retirement visas
                  & more. WORK PERMITS FOR FOREIGN NATIONALS TO WORK LEGALLY.
                </p>
                <p>
                  all foreign national who wish to work in Thailand are required
                  by Law to hold a valid work visa and a work permit. Not only
                  is this a very complicated process but there are numerous jobs
                  a foreigners are not permitted to work. Contact our team for
                  expert advice and to help you obtain a legal work permit.
                  OVERSEAS VISA SERVICES FOR THAI NATIONALS.
                </p>
                <p>
                  Getting a visa for Thai nationals to travel into the European
                  union can be a very tricky process with most western countries
                  having strict applications and requirements. A lot of complex
                  documentation is required which varies from country to country
                  which is why it is always best to seek the help of an
                  experience team to help increase your chances of success in
                  obtaining a visa to travel overseas.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("up", "tween", 0.7, 1.5)}
                  className="video-image"
                >
                  <img src="./assets/images/visaApp.png" alt="SPR-Accounting" />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.2, 1.5)}
                className="section-title"
              >
                <h2>thailand elite visas</h2>
              </motion.div>
            </div>
          </div>
          <div className="row reverse">
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("right", "tween", 0.5, 1.5)}
                  className="video-image"
                >
                  <img
                    src="./assets/images/thaiElite.png"
                    alt="SPR-Accounting"
                  />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>

            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("up", "tween", 0.7, 1.5)}
                className="choose-left"
              >
                <p>
                  thailand elite visas offer you an extended stay of 5, 10 or 20
                  year options with a host of different luxury v.i.p benefits
                  maliwan business and accounting is an authorized agent of the
                  thailand elite visa program, our team prepares and applies for
                  your long term elite stay visa at no extra cost, our fees are
                  exactly the same as the thailand elite visa department, so why
                  not sit back, relax and let us do all the paperwork for you at
                  no extra cost.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.2, 1.5)}
                className="section-title visa-section"
              >
                <h2>
                  VISA AND WORK PERMIT SERVICES TO KEEP YOUR STAY IN THAILAND
                  LEGAL
                </h2>
              </motion.div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("right", "tween", 0.5, 1.5)}
                className="choose-left"
              >
                <p>
                  it is your responsibility to hold a valid and legal visa
                  relating to your reason of stay in thailand and if your reason
                  of stay is for business then you will also be required to hold
                  a valid work permit. persons caught staying or working
                  illegally without a valid visa or work permit will be arrested
                  and face imprisonment, a fine or both the immigration system
                  in thailand is very complex and the requirements to obtain
                  visas or work permits constantly change making it a very
                  stressful, confusing & worrying experience for foreigners. it
                  is not uncommon for the requirements to vary at all local
                  immigration offices and it’s also not uncommon for the
                  requirements to be interpreted differently between different
                  officers.
                </p>
                <p>
                  Our office stays up to date with the constant changes and
                  requirements for visas and work permits and provides a full
                  professional service to deal with all types of visa
                  application needs as well as being an authorized agent for
                  Elite Visa Thailand.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>

            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("up", "tween", 0.7, 1.5)}
                  className="video-image"
                >
                  <img
                    src="./assets/images/thaiVisa.png"
                    alt="SPR-Accounting"
                  />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}
    </div>
  );
}

export default Visa;
