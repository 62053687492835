
export const Portfolio = [
  {
    img: "./assets/images/carousel/pf1.jpg",
  },
  {
    img: "./assets/images/carousel/pf2.jpg",
  },
  {
    img: "./assets/images/carousel/pf3.jpg",
  },
  {
    img: "./assets/images/carousel/pf4.jpg",
  },
  {
    img: "./assets/images/carousel/pf5.jpg",
  },
  { img: "./assets/images/carousel/pf6.jpg" },
  { img: "./assets/images/carousel/pf7.jpg" },
  { img: "./assets/images/carousel/pf8.jpg" },
];

export const sliderSettings = {
  infinite: true,
  autoplay: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  initialSlide: 0,
  touchMove: true,
  useCSS: true,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const HeroSettings = {
    infinite: true,
    autoplay: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    touchMove: true,
    useCSS: true
  };
