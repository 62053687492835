import React from 'react'
import './Hero.css'
import Slider from 'react-slick'
import { HeroSettings } from '../utils/image_data'


function Hero() {
  return (
    <>
        {/* <!-- Slider Area --> */}
		<section className="slider">
			<Slider {...HeroSettings}>
			{/* <!-- Start Single Slider --> */}
			<div className="single-slider slider1">
					<div className="container">
						<div className="row">
							<div className="col-lg-7">
								<div className="text">
									<h1>We Provide <span>Accounting</span> Services That You Can <span>Trust!</span></h1>
									<p>So you have taken the plunge and opened your own business and you are rightfully excited, but running a business is more than just making sales. It is important that all businesses keep good, accurate bookkeeping for a number of reasons.</p>
									<div className="button">
										<a href="/contact" className="btn">Get in touch</a>
										<a href="tel:077425201" className="btn primary">Contact Now</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- End Single Slider --> */}
				{/* <!-- Start Single Slider --> */}
				<div className="single-slider slider2">
					<div className="container">
						<div className="row">
							<div className="col-lg-7">
								<div className="text">
									<h1>We Provide <span>Legal</span> Services That You Can <span>Believe!</span></h1>
									<p>We are happy to help all tourists. who have legal problems during the trip We have experts available to help you in every aspect. </p>
                                    <ul className="mt-4 ml-4">
                                        <li>Property & Land Law</li>
                                        <li>Wills & Probate</li>
                                    </ul>
									<div className="button">
										<a href="/contact" className="btn">Get in touch</a>
										<a href="tel:077425201" className="btn primary">Contact Now</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- Start End Slider --> */}
				{/* <!-- Start Single Slider --> */}
				<div className="single-slider slider3">
					<div className="container">
						<div className="row">
							<div className="col-lg-7">
								<div className="text">
									<h1>We Provide <span>Visa and Work Permit</span> Services That You Can <span>Trust!</span></h1>
									<p>The immigration system in Thailand is very complex and the requirements to obtain visas or work permits constantly change making it a very stressful, confusing & worrying experience for foreigners.
										It is not uncommon for the requirements to vary at all local immigration offices and it’s also not uncommon for the requirements to be interpreted differently between different officers.</p>
									<div className="button">
										<a href="/contact" className="btn">Get in touch</a>
										<a href="tel:077425201" className="btn primary">Contact Now</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- End Single Slider --> */}
			</Slider>
		</section>
		{/* <!--/ End Slider Area --> */}
    </>
  )
}

export default Hero