import React from "react";
import AboutUs from "../components/aboutUs/AboutUs";
import Carousel from "../components/carousel/Carousel";
import ContactsForm from "../components/contactsForm/ContactsForm";
import Footer from "../components/footer/Footer";
import Hero from "../components/hero/Hero";
import Navbar from "../components/navbar/Navbar";
import Ourservices from "../components/ourService/Ourservices";
import Schedule from "../components/schedule/Schedule";
import Loading from "../components/loading/Loading";

function Homepage() {
  
  return (
    <>
      <Loading/>
      <Navbar/>
      <Hero/>
      <Schedule/>
      <AboutUs/>
      <Carousel/>
      <Ourservices/>
      <ContactsForm/>
      <Footer/>
    </>
  );  
}

export default Homepage;
