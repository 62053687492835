import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import Loading from '../components/loading/Loading'
import ContactSection from '../components/contactPage/ContactSection'

function Contactpage() {
  return (
    <>
        <Loading/>
        <Navbar/>
        <ContactSection/>
        <Footer/>
    </>
  )
}

export default Contactpage