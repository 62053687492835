import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import AccountingPage from '../components/accounting/AccountingPage'
import Loading from '../components/loading/Loading'

function Accounting() {

  return (
    <>
        <Loading/>
        <Navbar/>
        <AccountingPage/>
        <Footer/>
    </>
  )
}

export default Accounting