import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./carousel.css";
import { Portfolio, sliderSettings } from "../utils/image_data";

function Carousel() {
  return (
    <>
      {/* <!-- Start portfolio --> */}
      <section className="portfolio section">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>WE GO THE EXTRA MILE TO PROVIDE A MORE PERSONAL SERVICE</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-12">
              <Slider
                {...sliderSettings}
                className="carousel"
              >
                {Portfolio.map((Portfolio) => (
                  <div className="card_item ">
                    <img src={Portfolio.img} alt="SPR-Accounting" />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </section>
      {/* <!--/ End portfolio --> */}
    </>
  );
}

export default Carousel;
