import React from 'react'
import './Navbar.css'
import { Link } from 'react-router-dom'

function Navbar() {
    
  return (
    <> 
		<header className="header" >
			<div className="header-inner">
				<div className="container">
					<div className="inner">
						<div className="row">
							<div className="col-lg-3 col-md-3 col-12">
								<div className="logo">
									<a href="/"><img src="assets/images/logo.jpg" alt="SPR ACCOUNTING & BUSINESS" /></a>
								</div>
								<div className="mobile-nav"></div>
							</div>
							<div className="col-lg-9 col-md-9 col-12">
                                <div className="main-menu">
                                    <nav className="navigation">
                                        <ul className="nav menu">
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li><a href="#fun-facts">About Us</a></li>
                                            <li><a href="#/">Services<i class="icofont-rounded-down"></i></a>
                                                <ul className="dropdown">
                                                    <li><Link to="/accounting">Accounting</Link></li>
                                                    <li><Link to="/legal">Legal</Link></li>
                                                    <li><Link to="/visa-and-workpermit">Visa and work permit</Link></li>
                                                </ul>
											</li>
                                            <li>
                                                <Link to="/contact">Contact Us</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
    </>
  )
}

export default Navbar