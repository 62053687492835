import React from "react";
import "./legal.css";
import { motion } from "framer-motion";
import { fadeIn, staggerChildren } from "../utils/motion";

function Legal() {
  return (
    <div className="legal-body">
      {/* <!-- Breadcrumbs --> */}
      <div className="breadcrumbs legal overlay">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Legal Service</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Breadcrumbs --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.2, 1.5)}
                className="section-title"
              >
                <h2>Police Station Assistance</h2>
              </motion.div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("right", "tween", 0.5, 1.5)}
                className="choose-left"
              >
                <p>
                  We hope you don’t ever need to visit the police station or are
                  taken into custody, but if you are we offer police station
                  assistance to make sure you are treated fairly within the law.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("up", "tween", 0.7, 1.5)}
                  className="video-image"
                >
                  <img src="./assets/images/police.jpg" alt="SPR-Accounting" />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.2, 1.5)}
                className="section-title"
              >
                <h2>Property & Land Law</h2>
              </motion.div>
            </div>
          </div>
          <div className="row reverse">
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("right", "tween", 0.5, 1.5)}
                  className="video-image"
                >
                  <img
                    src="./assets/images/Property&LandLaw.jpg"
                    alt="SPR-Accounting"
                  />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>

            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("up", "tween", 0.7, 1.5)}
                className="choose-left"
              >
                <p>
                  All of your due diligence, sale and purchase agreements,
                  transfers under one roof as well as legal cases such a land
                  encroachment & trespassing. We will make sure your sale or
                  purchase doesn’t turn into a nightmare.
                </p>
                <p>
                  Foreigners should be especially careful and seek professional
                  legal assistance when purchasing property or land in Thailand
                  to make sure everything is as it should be and that you do not
                  end up losing your hard earned savings to a scam or otherwise.
                </p>
                <p>
                  Foreigners cannot directly own land of property in Thailand
                  but rather the land or property can be held by a registered
                  company of which a foreigner can be director with full power
                  of attorney. We handle the full set up of registered Thai
                  companies for foreigners as well as the complete transfer
                  process. Contact our team today for your free consultation.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.2, 1.5)}
                className="section-title"
              >
                <h2>Wills & Probate</h2>
              </motion.div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("right", "tween", 0.5, 1.5)}
                className="choose-left"
              >
                <p>
                  It isn’t always the topic people like to talk about but it is
                  an important one. It is always best to prepare for the future
                  for the peace of mind of your loved ones.
                </p>
                <p>
                  We prepare detailed Wills and assist with the legal aspects
                  regarding assets and estate distribution, child custody in the
                  sad event of death of parent & more. Contact the team today to
                  discuss making a will or updating an existing one.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>

            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("up", "tween", 0.7, 1.5)}
                  className="video-image"
                >
                  <img
                    src="./assets/images/Wills&Probate.jpg"
                    alt="SPR-Accounting"
                  />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.5, 1.5)}
                className="section-title"
              >
                <h2>Business & licenses services Company registration</h2>
              </motion.div>
            </div>
          </div>
          <div className="row reverse">
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("right", "tween", 1, 1.5)}
                  className="video-image"
                >
                  <img
                    src="./assets/images/BusinessLicenses.jpg"
                    alt="SPR-Accounting"
                  />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>

            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("up", "tween", 1.5, 1.5)}
                className="choose-left"
              >
                <p>
                  Looking to register a business in Thailand? Not sure where to
                  start?
                </p>
                <p>
                  We offer professional advice on opening a company in Thailand
                  and hold your hand every step of the way, explaining
                  everything along the way while we complete your company
                  registration for either Co,.Ltd, sole trader,Limited
                  partnership or BOI company types.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.5, 1.5)}
                className="section-title"
              >
                <h2>Company sales & transfers</h2>
              </motion.div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("right", "tween", 1, 1.5)}
                className="choose-left"
              >
                <p>
                  Are you selling your company or perhaps you are buying a
                  business? Our team take the stress away and make sure the sale
                  and transfer of businesses goes smoothly and legally.
                </p>
                <p>
                  Areas we check for clients who are looking at purchasing a
                  business include checking that the business is legal and that
                  all taxes are paid up to date, the contracts are in good
                  standing order, rental agreements are binding and that there
                  is no outstanding debt.
                </p>
                <p>
                  Once we have completed our checks our team get to work on
                  transferring ownership to the new owner.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("up", "tween", 1.5, 1.5)}
                  className="video-image"
                >
                  <img
                    src="./assets/images/CompanySales.png"
                    alt="SPR-Accounting"
                  />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.5, 1.5)}
                className="section-title"
              >
                <h2>Company Structure Changes</h2>
              </motion.div>
            </div>
          </div>
          <div className="row reverse">
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("right", "tween", 1, 1.5)}
                  className="video-image"
                >
                  <img
                    src="./assets/images/company-structure.png"
                    alt="SPR-Accounting"
                  />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>
            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("up", "tween", 1, 1.5)}
                className="choose-left"
              >
                <p>
                  Our office provide full company structure changes for DBD
                  registered businesses including director additions, director
                  removals, shareholder additions, shareholder removals & share
                  percentages restructuring.
                </p>
                <p>
                  You don’t have to be our full time client in order to book our
                  company structure changes services, simply contact the team
                  today to discuss your needs for a very efficient service and
                  wallet friendly rates.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.5, 1.5)}
                className="section-title"
              >
                <h2>guest house & hotel licences</h2>
              </motion.div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("right", "tween", 1, 1.5)}
                className="choose-left"
              >
                <p>
                  All guest houses and hotels in Thailand require a licence, for
                  guesthouses under a certain number of rooms a short stay
                  licence can suffice in most cases while larger guest houses or
                  hotels are required to posses the very complex and and
                  difficult – Hotel licence.
                </p>
                <p>
                  A hotel licence involves a complicated and difficult process
                  to obtain and therefore should be handled by an experienced
                  attorney in order to get a successful application. Contact our
                  team today to start your guesthouse or hotel licence.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("up", "tween", 1.5, 1.5)}
                  className="video-image"
                >
                  <img src="./assets/images/hotel.png" alt="SPR-Accounting" />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.5, 1.5)}
                className="section-title"
              >
                <h2>restaurant licences</h2>
              </motion.div>
            </div>
          </div>
          <div className="row reverse">
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("right", "tween", 1, 1.5)}
                  className="video-image"
                >
                  <img
                    src="./assets/images/restaurant.png"
                    alt="SPR-Accounting"
                  />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>
            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("up", "tween", 1.5, 1.5)}
                className="choose-left"
              >
                <p>
                  Registered restaurant businesses in Thailand are required to
                  hold a valid food licence, it isn’t a particularly complex
                  licence to obtain but there are a few important requirements
                  of your restaurant in order to successfully obtain one. If you
                  require assistance or would like us to handle the process for
                  you then contact our team who will be glad to assist you.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.5, 1.5)}
                className="section-title"
              >
                <h2>alcohol & tobacco licences</h2>
              </motion.div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("right", "tween", 1, 1.5)}
                className="choose-left"
              >
                <p>
                  If your business sells alcohol or tobacco then you are
                  required by Thai law to hold a valid licence accordingly.
                  There is also a different imported alcohol licence required
                  for business who sell certain imported alcohol.
                </p>
                <p>
                  None are particularly difficult to obtain but if you just want
                  somebody to complete the processes for you so you can save
                  your own time and focus on the running of your business we
                  will be more than happy to assist.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("up", "tween", 1.5, 1.5)}
                  className="video-image"
                >
                  <img src="./assets/images/alcohol.png" alt="SPR-Accounting" />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}

      {/* <!-- Start Why choose --> */}
      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.5, 1.5)}
                className="section-title"
              >
                <h2>construction permits</h2>
              </motion.div>
            </div>
          </div>
          <div className="row reverse">
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("right", "tween", 1, 1.5)}
                  className="video-image"
                >
                  <img
                    src="./assets/images/contruuction.png"
                    alt="SPR-Accounting"
                  />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>
            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("up", "tween", 1.5, 1.5)}
                className="choose-left"
              >
                <p>
                  A construction permit is required by law to build, demolish or
                  change the structure of a previously approved building in
                  Thailand. Not obtaining a construction permit can land you in
                  serious hot water and in the worst cases lead to your new
                  building being demolished.
                </p>
                <p>
                  Always obtain a building permit if you have any plans to build
                  or modify a build in Thailand.Our team can help you get the
                  right permits for the job to operate legally, contact the team
                  today for your free consultation.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
          </div>
        </div>
      </motion.section>
      {/* <!--/ End Why choose --> */}

      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.5, 1)}
                className="section-title"
              >
                <h2>operating a tourism based business</h2>
              </motion.div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div
                variants={fadeIn("right", "tween", 1, 1)}
                className="choose-left"
              >
                <p>
                  There are different types and levels of TAT licence in
                  Thailand relating to the field in which your business falls
                  into, Businesses such a tour shops, scuba diving, mini van,
                  boat tours are all required to hold a type of TAT licence by
                  law.
                </p>
                <p>
                  Each different type of TAT licence level may require other
                  relevant factors such asspecific insurances which is why it is
                  important to hire an experienced attorney to assit in your
                  quest.
                </p>
                <p>
                  Our team advise on the licences required for your specific
                  business type and help you obtain the relevant licences to
                  operate legally.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("up", "tween", 1.5, 1)}
                  className="video-image"
                >
                  <img src="./assets/images/tourist.png" alt="SPR-Accounting" />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>
          </div>
        </div>
      </motion.section>

      <motion.section
        initial="hidden"
        whileInView="show"
        variants={staggerChildren}
        viewport={{ once: false, amount: 0.25 }}
        className="why-choose section"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <motion.div
                variants={fadeIn("down", "tween", 0.5, 1)}
                className="section-title"
              >
                <h2>TAT licences</h2>
              </motion.div>
            </div>
          </div>
          <div className="row reverse">
            <div className="col-lg-6 col-12 right-con">
              {/* <!-- Start Choose Rights --> */}
              <div className="choose-right">
                <motion.div
                  variants={fadeIn("right", "tween", 1, 1)}
                  className="video-image"
                >
                  <img
                    src="./assets/images/guide-tour.png"
                    alt="SPR-Accounting"
                  />
                </motion.div>
              </div>
              {/* <!-- End Choose Rights --> */}
            </div>
            <div className="col-lg-6 col-12 left-con">
              {/* <!-- Start Choose Left --> */}
              <motion.div variants={fadeIn("up", "tween", 1.5, 1)} className="choose-left">
                <p>
                  There are different levels of TAT ( travel and tourism licence
                  ) which all offer a different level of coverage and serve
                  different purposes. Examples of requiring TAT licences are
                  tour shops selling tour tickets, tour mini van companies, boat
                  charter companies & diving companies to name just a few.
                </p>
              </motion.div>
              {/* <!-- End Choose Left --> */}
            </div>
          </div>
        </div>
      </motion.section>
    </div>
  );
}

export default Legal;
