import React from 'react'
import Loading from '../components/loading/Loading'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import Legal from '../components/legal/Legal'

function LegalPage() {
  
  return (
    <>
        <Loading/>
        <Navbar/>
        <Legal/>
        <Footer/>
    </>
  )
}

export default LegalPage