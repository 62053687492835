import React from 'react'
import './aboutUs.css'

function AboutUs() {
  return (
    <>
        {/* <!-- Start Fun-facts --> */}
		<div id="fun-facts" className="fun-facts section overlay">
			<div className="container">
				<div className="row">
					<div className="col-lg-3 col-md-6 col-12">
						{/* <!-- Start Single Fun --> */}
						<div className="single-fun">
							<i className="icofont icofont-home"></i>
							<div className="content">
								<span className="counter">2</span>
								<p>branches</p>
							</div>
						</div>
						{/* <!-- End Single Fun --> */}
					</div>
					<div className="col-lg-3 col-md-6 col-12">
						{/* <!-- Start Single Fun --> */}
						<div className="single-fun">
							<i className="icofont icofont-user-alt-3"></i>
							<div className="content">
								<span className="counter">20</span>
								<p>Accounting and legal experts</p>
							</div>
						</div>
						{/* <!-- End Single Fun --> */}
					</div>
					<div className="col-lg-3 col-md-6 col-12">
						{/* <!-- Start Single Fun --> */}
						<div className="single-fun">
							<i className="icofont-simple-smile"></i>
							<div className="content">
								<span className="counter">8902</span>
								<p>Customers we have served</p>
							</div>
						</div>
						{/* <!-- End Single Fun --> */}
					</div>
					<div className="col-lg-3 col-md-6 col-12">
						{/* <!-- Start Single Fun --> */}
						<div className="single-fun">
							<i className="icofont icofont-table"></i>
							<div className="content">
								<span className="counter">5</span>
								<p>Years of Experience</p>
							</div>
						</div>
						{/* <!-- End Single Fun --> */}
					</div>
				</div>
			</div>
		</div>
		{/* <!--/ End Fun-facts --> */}
		
		{/* <!-- Start Why choose --> */}
		<section className="why-choose section" >
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
						<div className="section-title">
							<h2>About Us</h2>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6 col-12">
						{/* <!-- Start Choose Left --> */}
						<div className="choose-left">
							<h3>Who We Are</h3>
							<p>SPR Accounting & business is one of koh samui's leading accountant & legal firms providing professional services to businesses and individuals across koh samui's, koh phangan & koh tao.</p>
							<p>Our office provides the professional advice and services businesses need for all of their accounting, legal, visa and work permit needs.
                                Our accounting team handle all of your business accounts, audits, tax returns, VAT and book keeping while our highly skilled legal team of attorneys handle a wide range of legal services from business registrations, property sales transfers, due diligence, wills, contract drafting, legal translations, litigation and corporate legal services.
                                We are proud to stand out from the crowd by offering a truly personalized passionate caring service to all of our clients.</p>
							<div className="row">
								<div className="col-lg-6 col-md-6 col-12">
									<ul className="list">
										<li><i className="fa fa-caret-right"></i>Accounting</li>
										<li><i className="fa fa-caret-right"></i>VAT</li>
										<li><i className="fa fa-caret-right"></i>Business registrations</li>
										<li><i className="fa fa-caret-right"></i>Business transfers</li>
										<li><i className="fa fa-caret-right"></i>Financial audits</li>
										<li><i className="fa fa-caret-right"></i>Legal services</li>
										<li><i className="fa fa-caret-right"></i>Licensing</li>
                                        <li><i className="fa fa-caret-right"></i>Court representation</li>
									</ul>
								</div>
								<div className="col-lg-6 col-md-6 col-12">
									<ul className="list">
                                        <li><i className="fa fa-caret-right"></i>Due diligence</li>
										<li><i className="fa fa-caret-right"></i>Property and land sales</li>
										<li><i className="fa fa-caret-right"></i>Land office assistance</li>
										<li><i className="fa fa-caret-right"></i>Contracts</li>
										<li><i className="fa fa-caret-right"></i>Certified translations</li>
										<li><i className="fa fa-caret-right"></i>Notary</li>
										<li><i className="fa fa-caret-right"></i>work permit</li>
										<li><i className="fa fa-caret-right"></i>Visa all type</li>
									</ul>
								</div>
							</div>
						</div>
						{/* <!-- End Choose Left --> */}
					</div>
					<div className="col-lg-6 col-12 right-con">
						{/* <!-- Start Choose Rights --> */}
						<div className="choose-right">
							<div className="video-image">
								<img src="./assets/images/aboutUs.png" alt="SPR-Accounting"/>
							</div>
						</div>
						{/* <!-- End Choose Rights --> */}
					</div>
				</div>
			</div>
		</section>
		{/* <!--/ End Why choose --> */}

        {/* <!-- Start Call to action --> */}
		<section className="call-action overlay">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-12">
						<div className="content">
							<h2>Business services to keep your business moving forward, legal and up-to-date.</h2>
							<p>We offer professional advice on opening a company in Thailand and hold your hand every step of the way, explaining everything along the way while we complete your company registration for either Co,.Ltd, sole trader,Limited partnership or BOI company types.</p>
							<div className="button">
								<a href="tel:077425201" className="btn">Contact Now</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		{/* <!--/ End Call to action --> */}
    </>
  )
}

export default AboutUs