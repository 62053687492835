import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import "./contacts.css";

function ContactsForm() {

  const [status, setStatus] = useState("Send a message")
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_hpxxipc', 'template_51uhasi', form.current, 'a99Vva6_bn0Kfmf2b')
    .then(
      (result) => {
        console.log(result.text);
        setStatus("Thank you for the message. We will reply as soon");
        e.target.reset();
      },
      (error) => {
        console.log(error.text);
        setStatus("Send..fail");
      }
    );
  };

  return (
    <>
      {/* <!-- Start Appointment --> */}
      <section className="appointment">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title">
                <h2>We Are Always Ready to Help You.</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-12">
              <form ref={form} onSubmit={sendEmail}  className="form">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-12">
                    <div className="form-group">
                      <input id="phone" name="phone" type="number" placeholder="Phone" />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="form-group">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Email"
                        required
                       
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="form-group">
                      <textarea
                        id="message"
                        name="message"
                        placeholder="Write Your Message Here....."
                        required
                        
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5 col-md-4 col-12">
                    <div className="form-group">
                      <div className="button">
                        <button  type="submit"  className="btn">
                          {status}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-lg-6 col-md-12 ">
              <div className="appointment-image">
                <img
                  src="./assets/images/contact-img.png"
                  alt="SPR-Accounting"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Appointment --> */}
    </>
  );
}

export default ContactsForm;
