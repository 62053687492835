import React from "react";
import "./contactPage.css";

function ContactSection() {
  return (
    <>
      <div className="breadcrumbs overlay">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Contact Us</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="contact-us section">
        <div className="container">
          <div className="inner">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-us-left">
                  <div id="myMap">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3951.163777931823!2d98.3631674!3d7.982016000000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30503716792e19e9%3A0xb86d142f6fca9247!2sSPR%20ACCOUNTING%20PHUKET%20CO.%2CLTD.!5e0!3m2!1sth!2sth!4v1696669117089!5m2!1sth!2sth"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      title="SPR-Accounting"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-us-form">
                  <h2>Phuket Branch</h2>
                  <p>
                    If you have any questions please fell free to contact with
                    us.
                  </p>
                  <div className="phuket-whatsApp">
                    <img
                      src="./assets/images/phuket-whatsapp.png"
                      alt="SPR-Accounting"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-info">
            <div className="row">
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont icofont-ui-call"></i>
                  <div className="content">
                    <h3>
                      <a href="tel:0623832299">062-383-2299</a>
                    </h3>
                    <p>
                      <a href="mailto:yim@spraccounting.co">
                        yim@spraccounting.co
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont-google-map"></i>
                  <div className="content">
                    <h3>111, 41 Sri Sunthon Rd.,</h3>
                    <p>
                      Si Sunthon Subdistrict, Thalang District, Phuket 83110
                    </p>
                    <p>Phuket 83110</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont icofont-wall-clock"></i>
                  <div className="content">
                    <h3>Mon - Fri: 9am - 5pm</h3>
                    <p>Saturday and Sunday closed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-us section">
        <div className="container">
          <div className="inner">
            <div className="row">
              <div className="col-lg-6">
                <div className="contact-us-left">
                  <div id="myMap">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3934.5147153337484!2d100.0350381!3d9.5508214!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3054f170feb7aa75%3A0xec7d05a03198af0b!2sSPR%20ACCOUNTING%20%26%20BUSINESS!5e0!3m2!1sth!2sth!4v1696672287449!5m2!1sth!2sth"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      title="SPR-Accounting"
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact-us-form">
                  <h2>Koh Samui Branch</h2>
                  <p>
                    If you have any questions please fell free to contact with
                    us.
                  </p>
                  <div className="phuket-whatsApp">
                    <img
                      src="./assets/images/samui-whatsApp.png"
                      alt="SPR-Accounting"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-info">
            <div className="row">
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont icofont-ui-call"></i>
                  <div className="content">
                    <h3>
                      <a href="tel:0928192363">092-819-2363</a>
                    </h3>
                    <p>
                      <a href="mailto:yim@spraccounting.co">
                        yim@spraccounting.co
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont-google-map"></i>
                  <div className="content">
                    <h3>151/33 Koh Samui District,</h3>
                    <p>
                        Surat Thani 84320
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12 ">
                <div className="single-info">
                  <i className="icofont icofont-wall-clock"></i>
                  <div className="content">
                    <h3>Mon - Fri: 9am - 5pm</h3>
                    <p>Saturday and Sunday closed</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactSection;
